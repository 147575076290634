import { Component, OnDestroy } from '@angular/core';
import { IArticleInfo, ICategory, ILanguage, IUserInfo } from '@app/shared/app.interface';
import { ClientService } from '@app/shared/services/client.service';
import { ToolsService } from '@app/shared/services/tools.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { DialogService } from 'primeng/dynamicdialog';
import { mergeMap } from 'rxjs/operators';
import { AddArticleComponent } from './add-article/add-article.component';

@Component({
	selector: 'articles-editor',
	templateUrl: './articles.component.html',
	providers: [DialogService],
})
export class ArticlesFormComponent implements OnDestroy {
	articles: IArticleInfo[] = [];
	reorderIsActive = false;
	clonedArticles: { [s: string]: IArticleInfo } = {};
	users: IUserInfo[] = [];
	languages: { [s: string]: ILanguage } = {};
	categories: { [s: string]: ICategory } = {};

	constructor(
		public tools: ToolsService,
		private client: ClientService,
		public dialogService: DialogService,
	) {
		tools
			.apiStates('_data')
			.pipe(
				untilDestroyed(this),
				mergeMap((_data: any) => {
					this.users = _data.users;
					_data.languages?.map((item) => {
						this.languages[item._id] = item;
					});

					_data.categories?.map((item) => {
						this.categories[item._id] = item;
					});

					return tools.apiStates('misc');
				}),
			)
			.subscribe((misc) => {
				this.articles = (
					misc.map((item) => {
						item.order = +item.order;
						return Object.assign({}, item, {
							author: this.users.find((_user) => {
								return _user._id === item._author;
							}),
						});
					}) || []
				).sort((a, b) => {
					return a.order - b.order;
				});
			});
	}

	toggleAddNewContent() {
		const ref = this.dialogService.open(AddArticleComponent, {
			header: 'Add New Article',
			width: '700px',
			styleClass: 'addNewArticleDialog',
			data: {
				articles: this.articles,
				languages: this.languages,
				categories: this.categories,
			},
		});

		ref.onClose.subscribe((_) => {
			if (_) {
				this.client.addNewMisc(_);
			}
		});
	}

	editArticle(articleId: string) {
		const article = this.articles.find((item) => item._id === articleId);
		const ref = this.dialogService.open(AddArticleComponent, {
			header: 'Edit Article',
			width: '680px',
			styleClass: 'addNewArticleDialog',
			data: {
				article: article,
				languages: this.languages,
				categories: this.categories,
				editMode: true,
			},
		});

		ref.onClose.subscribe((item) => {
			if (item) {
				item = {
					...item,
					_id: articleId,
					categories: [item.categories],
					_author: article._author,
					author: article.author,
					oldFileMetaData: article.fileMetaData || '',
				};
				this.client.updateArticleMisc(item);
			}
		});
	}

	onRowDeleteInit(article: IArticleInfo) {
		if (confirm('Are you sure to delete article #' + article.order)) {
			this.client.deleteMisc(article);
		}
	}

	rowReorder(e) {
		this.articles = this.articles.map(
			(article, index) => ((article.order = (index + 1).toString()), article),
		);
		this.client.reorderMisc(this.articles.map((article) => ({ _id: article._id, order: article.order })));
	}

	ngOnDestroy() {}
}
