import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { calculateReadingTime, getFileReadingTime } from '@app/helpers/reading-time-calculator';
import { ICategory, ILanguage } from '@app/shared/app.interface';
import { FormService } from '@app/shared/services/forms.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Subscription } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '@app/shared/services/toast.service';

@Component({
	selector: 'add-article',
	templateUrl: './add-article.component.html',
	providers: [FormService],
})
export class AddArticleComponent implements OnInit, OnDestroy {
	@HostListener('window:resize', ['$event'])
	onResize(event: Event) {
		this.resizeHtmlEditor();
	}

	isEditing = false;
	fullscreenToggle = false;
	categories = [];
	languages = [];
	imageSizeError = false;
	htmlSizeError = false;
	maxImageSize = '300000'; // 300kb
	maxHtmlSize = '100000'; // 100kb
	htmlEditorConfig: AngularEditorConfig;
	htmlContent = '';

	private _unsubscribe$ = new Subscription();

	constructor(
		public fs: FormService,
		private ref: DynamicDialogRef,
		private config: DynamicDialogConfig,
		private httpClient: HttpClient,
		private toast: ToastService,
	) {
		this.htmlEditorConfig = {
			editable: false,
			spellcheck: true,
			height: '15rem',
			minHeight: '5rem',
			placeholder: 'Upload html file first',
			translate: 'no',
			defaultParagraphSeparator: 'p',
			defaultFontName: `Ubuntu,'Helvetica Neue',Helvetica,Arial,sans-serif`,
			fonts: [
				{
					class: `Ubuntu,'Helvetica Neue',Helvetica,Arial,sans-serif`,
					name: `Ubuntu,'Helvetica Neue',Helvetica,Arial,sans-serif`,
				},
				{ class: 'arial', name: 'Arial' },
				{ class: 'times-new-roman', name: 'Times New Roman' },
				{ class: 'calibri', name: 'Calibri' },
				{ class: 'comic-sans-ms', name: 'Comic Sans MS' },
			],
		};

		fs.init('blogArticle');
		if (!this.config.data.editMode) {
			fs.getField('order').patchValue(this.config.data.articles.length + 1);
		}
	}

	ngOnInit() {
		for (const [_, value] of Object.entries(this.config.data.categories as { [s: string]: ILanguage })) {
			this.categories.push({ label: value.name, value: value._id });
		}

		for (const [_, value] of Object.entries(this.config.data.languages as { [s: string]: ICategory })) {
			this.languages.push({ label: value.name, value: value._id });
		}

		if (this.config.data.article && this.config.data.editMode) {
			const article = this.config.data.article;
			this.fs.form.patchValue({
				title: article.title,
				metaTitle: article?.metaTitle,
				description: article.description,
				order: article.order,
				file: article.fileUrl,
				image: article.imageUrl,
				fileMetaData: article.fileMetaData ?? '',
				language: article.language,
				categories: article.categories[0],
				readingTime: article.readingTime,
			});

			this.httpClient
				.get(`/v1/public/s3/${article.fileMetaData ?? ''}${article.fileUrl}`, { responseType: 'text' })
				.subscribe({
					next: (response: string) => {
						this.fs.form.get('htmlEditor').patchValue(response);
						this._updateHTMLEditorConfig(true);
					},
					error: (error) => {
						this.toast.set('Error while fetching article file', error, 'error');
						console.error('Error fetching the file', error);
					},
				});
		}

		this._unsubscribe$.add(
			this.fs.form
				.get('htmlEditor')
				.valueChanges.pipe(
					tap((res) => (this.isEditing = true)),
					debounceTime(500),
				)
				.subscribe((res) => {
					const _fileName = this.fs.form.get('file').value?.name ?? this.fs.form.get('file').value;

					if (!_fileName) {
						this.fs.form.get('readingTime').patchValue(null);
						this.fs.form.get('file').patchValue(null);
					} else {
						this.fs.form.get('readingTime').patchValue(calculateReadingTime(res));

						const blob = new Blob([this.fs.form.get('htmlEditor').value], { type: 'text/plain' });
						const file = new File([blob], _fileName, { type: 'text/html' });

						this.fs.form.get('file').patchValue(file);
					}

					this.isEditing = false;
				}),
		);
	}

	async onSave() {
		if (this.fs.formValid) {
			if (this.config.data.editMode) {
				await this.httpClient
					.get(`/v1/public/s3/${this.fs.form.get('fileMetaData').value}${this.fs.form.get('image').value}`,
						{ responseType: 'blob' },
						// {
						// responseType: 'text',
						// }
					)
					.subscribe({
						next: (response) => {
							// const objectURL = URL.createObjectURL(response);
							// console.log('objectURL', objectURL);
							// this.fs.form.get('image').patchValue(response);


							const fileName = this.fs.form.get('image').value; // Assuming the form field contains the file name
							const fileType = response.type; // The MIME type of the blob
							const file = new File([response], fileName, { type: fileType });

							// Patch the reconstructed file back into the form
							this.fs.form.get('image').patchValue(file);

							this.ref.close(this.fs.form.value)

							this.ref.close(this.fs.form.value);
						},
						error: (error) => {
							this.toast.set('Error while fetching article image', error, 'error');
							console.error('Error fetching the image', error);
						},
					});
			}
			else {
				this.ref.close(this.fs.form.value);
			}
		}
	}

	onCancel() {
		this.ref.close(null);
	}

	onSelectFile(e) {
		if (this.fs.form.get('file').value) {
			this.fs.form.get('file').patchValue(null);
			this.fs.form.get('readingTime').patchValue(null);
		}

		const file = e.currentFiles[0];
		if (file.size > parseInt(this.maxHtmlSize)) {
			this.htmlSizeError = true;
			return;
		}

		this.fs.form.get('file').patchValue(file);
		if (file) {
			getFileReadingTime(file).then((res: { readingTime: number; htmlContent: string }) => {
				this.fs.form.get('readingTime').patchValue(res.readingTime);
				this.fs.form.get('htmlEditor').patchValue(res.htmlContent);
				this._updateHTMLEditorConfig(true);
			});
		}
	}

	onRemoveFile(e) {
		this.fs.form.get('file').patchValue(null);
		this.fs.form.get('readingTime').patchValue(null);
		this.fs.form.get('htmlEditor').patchValue(null);

		this._updateHTMLEditorConfig(false);
	}

	onSelectImage(e) {
		if (this.fs.form.get('image').value) this.fs.form.get('image').patchValue(null);

		const file = e.currentFiles[0];
		if (file.size > parseInt(this.maxImageSize)) {
			this.imageSizeError = true;
			return;
		}
		if (file) this.fs.form.get('image').patchValue(file);
	}

	onRemoveImage(e) {
		this.fs.form.get('image').patchValue(null);
	}

	onFullscreenToggle() {
		this.fullscreenToggle = !this.fullscreenToggle;
		document
			.getElementsByClassName('addNewArticleDialog')?.[0]
			.classList[this.fullscreenToggle ? 'add' : 'remove']('fullscreen');
	}

	ngAfterViewInit() {
		this.resizeHtmlEditor();
	}

	resizeHtmlEditor() {
		this.htmlEditorConfig = {
			...this.htmlEditorConfig,
			maxHeight: `${(window.innerHeight - 405) | 0}px`,
		};
	}

	private _updateHTMLEditorConfig(editable: boolean) {
		this.htmlEditorConfig = {
			...this.htmlEditorConfig,
			editable: editable,
			placeholder: editable ? 'Enter text here...' : 'Upload html file first',
		};
	}

	ngOnDestroy() {
		this.fs.clear();
	}
}
